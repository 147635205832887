import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
     &copy; MELVDEV 2022
    </div>
  )
}

export default Footer