// version inferieur à 9 : on crée ce fichier nous memes
import app from 'firebase/app';
// auth:
import 'firebase/firestore'

const config  = {
  apiKey: "AIzaSyBnslZ2fm9uLz_8z9mud-2zX1VhzDG9jhQ",
  authDomain: "melvdev-8647f.firebaseapp.com",
  projectId: "melvdev-8647f",
  storageBucket: "melvdev-8647f.appspot.com",
  messagingSenderId: "448541084767",
  appId: "1:448541084767:web:ce513efaba5761efa9dfea"
  
  };
  
  class Firebase {
    constructor(){
        app.initializeApp(config);
        this.db= app.firestore();
     
    }
    addMessage =() => this.db.collection("messagerie");
       }
  export default Firebase;

  //on va prendre l'instanciation de notre class firebase via le contexte
  // et la passer en tant que value props, afin de pouvoir la récupérer dans les enfants de app