import React, { useState } from 'react'

const NavA = ({nav}) => {

 const [choix, setChoix]= useState('projets');

 const choisir=(arg)=>{
  setChoix(arg);
  nav(arg);
 }


  return (
    <div className='nav1'>
      <div className={choix==='projets'? 'selected':''}  onClick={()=>choisir('projets')} >projets</div>
      <div  className={choix==='compet'? 'selected':''}  onClick={()=>choisir('compet')}>compétences</div>
      <div  className={choix==='contact'? 'selected':''}  onClick={()=>choisir('contact')}>me contacter</div>
    </div>
  )
}

export default NavA