import React, { useState } from 'react'
import prec from './../../../images/gauche.png'
import suiv from './../../../images/droite.png'
import NavB from '../Nav2';
import ecam49 from './../../../videos/ecam49.mp4';
import lfqnep from './../../../videos/lfqnep.mp4';
import bataille from './../../../videos/bataille.mp4';
import echecs from './../../../videos/echecs.mp4';
import set from './../../../videos/set.mp4';
import chaperon from './../../../videos/chaperon.mp4';
import wherec from './../../../videos/wherec.mp4';
import atelier from './../../../videos/atelier.mp4';
import custeel from './../../../videos/custeel.mp4';
import biwe from './../../../videos/biwe.mp4';
import mace from './../../../videos/mace.mp4';
import booleed from './../../../videos/booleed.mp4';
import botta from './../../../videos/botta.mp4';
import sensationRock from './../../../videos/melwin.mp4';
import http from './../../../images/http.png';

const Videos = () => {
  const projets = [
    {
      titre : "LA BOTTA", categorie:'app', demoUrl: botta, stack:"ReactJS / Firebase storage, database, hosting ", githubUrl:'',lien:'',
      descr: "Application web : sondage interactif de la meilleure équipe de joueurs de foot, en vue de la commercialisation de jeux de cartes italiens. Design responsive, interface d'administration permettant de créer des sondages, les modifier, les dupliquer, les supprimer et exploiter les résultats."
    },
    {
      titre: 'Ecam49', categorie: 'app', demoUrl: `${ecam49}`, stack: 'Angular, Firebase, animations CSS++', githubUrl: '',lien:'',
      descr: "Support interactif d'énigmes dans le cadre de la journée d'intégration des étudiants de l'ECAM (école d'ingénieurs industriels)."
    },
    
    {
      titre: 'Custeel', categorie: 'app', demoUrl: `${custeel}`, stack: 'Angular, Firebase auth, hosting, firestore, realtime database, canvasJS, paiement stripe, génération de PDF, emails automatiques, formulaires', githubUrl: '',
      lien:'https://custeel.be',
      descr: "Plateforme de commande de mobilier sur mesure en métal et bois : Mise en relation des artisans locaux et leurs potentiels clients (particuliers et architectes) - Configurateur de meubles - Calcul automatique et instantané des devis - Générateur de PDF (factures..)."
    },
    {
      titre : "SENSATION ROCK", categorie:'app', demoUrl: sensationRock, stack:"ReactJS / Redux / ExpressJS Node / MongoDB / Firebase storage", githubUrl:'',
      descr: "** projet en cours de réalisation ** Quiz musicaux en étroite collaboration avec les acteurs de l'évènementiel. Publicité et lots à gagner selon placement. Design responsive. Interface admin accessible pour le client non-voyant utilisant NVDA."
    },
    {
      titre: 'Le film-qui-n-existait-pas', categorie: 'app', demoUrl: `${lfqnep}`, stack: 'Angular, Firebase, animations CSS++', githubUrl: '',
      descr: "Projet participatif en ligne en collaboration avec le dessinateur M.La Mine."
    },
    {
      titre: 'Where-C', categorie: 'app', demoUrl: `${wherec}`, stack: 'PWA, API publique, Angular, API GoogleMaps', githubUrl: '',
      descr: "Application qui référence, décrit et géolocalise sur une carte les toilettes publiques bruxelloises les plus proches."
    },
    {
      titre: 'SET à thèmes', categorie: 'jeu', demoUrl: set, stack: 'Angular, Firebase', githubUrl: '',
      descr: "Jeu SET personnalisé avec possibilité de choisir un thème : poissons, pommes, bonbons, ... "
    },
    {
      titre: 'Echecs', categorie: 'jeu', demoUrl: echecs, stack: 'Angular, Firebase, sans logiciel externe', githubUrl: '',
      descr: "Jeu d'échecs se jouant à 2 joueurs. Pas de mode joueur vs ordinateur. Session user, invitation des amis au défi et historique des parties."
    },
    {
      titre: 'Chaperon rouge', categorie: 'jeu', demoUrl: chaperon, stack: 'Vanilla Javascript, from scratch, gestion des sprites', githubUrl: '',
      descr: 'Jeu mono-joueur, se joue avec les flèches du clavier.'
    },
    {
      titre: "L'atelier d'Anne", categorie: 'landing', demoUrl: atelier, stack: 'Angular, Firebase database-storage-hosting', githubUrl: '',
      lien:'https://anne-mathieu.be',
      descr: "Galerie en ligne d'exposition de peintures. Formulaire de contact."
    },
    {
      titre : "La Maison de l'Assurance", categorie:'landing', demoUrl: mace, stack:"Angular, Firebase hosting, extension Trigger Email, re-captcha Google, Api Maps statique Google", githubUrl:'',
      lien: "https://mace-assurance.fr",
      descr: "Site vitrine présentant La Maison de l'Assurance du Centre Est à Auxerre. Responsivité toutes tailles d'écran, possibilité de remplir un formulaire de contact captcha, carte google maps."
    },
    {
      titre : "BIWE", categorie:'landing', demoUrl: biwe, stack:"HTML / CSS / JS", githubUrl:'',lien:"https://biwe.be",
      descr: "Site vitrine présentant une formation IWE (International Welding Engineer). Possibilité de remplir un formulaire de contact et de télécharger un PDF. "
    }
,
{
  titre : "BOOLEEED", categorie:'landing', demoUrl: booleed, stack:"HTML / CSS ", githubUrl:'',
  descr: "Site vitrine présentant une application web et mobile de gestion automobile. Intégration d'une maquette Adobe XD au pixel prêt. Design responsive."
}
,
{
  titre: 'Bataille', categorie: 'jeu', demoUrl: bataille, stack: 'Angular, Firebase', githubUrl: '',
  descr: 'Jeu de cartes se jouant à 2 joueurs.'
}

,
{
  titre: 'Dermoguide', categorie: 'landing', demoUrl: bataille, stack: 'Angular, Firebase storage, database, hosting', githubUrl: '',
  descr: "Site vitrine de présentation d'une formation en dermatologie."
}


  ]
  ///-------------------------
  const [numProjet, setNumProjet] = useState(0);

  const [listing, setListing] = useState(projets);
  const trier = (arg) => {
    setNumProjet(0);
    if (arg !== 'tous') { setListing(projets.filter(e => e.categorie === arg)); }
    else { setListing(projets); }
  }

  //---------------------------
  const suivant = (arg) => {
    setNumProjet(numProjet + arg);
  }
  //---------------------------
  //---------------------------
  return (
    <div className='projets'>

      <NavB trier={trier} />
      <div className='videoo'>
        <h1>{listing[numProjet].titre} {listing[numProjet].lien &&<a id='http' href={listing[numProjet].lien} target="_blank" rel="noreferrer" ><img alt='http' src={http} /> lien du site</a>} </h1>
        <div className='descr'>{listing[numProjet].descr}
        </div>
        <div className='frame'>
          <div className='fleche' >
            {numProjet > 0 && <img onClick={() => suivant(-1)} src={prec} alt='suivant' />}
          </div>
          <div className='cadre'><video src={listing[numProjet].demoUrl} type='video/mp4' title='ok' controls autoPlay muted ></video></div>
          <div className='fleche' >
            {numProjet < listing.length - 1 && <img onClick={() => suivant(1)} src={suiv} alt='suivant' />}
          </div>
        </div>
        <div className="stack" >
          <p><span>Stack :</span> {listing[numProjet].stack} </p>
        </div>
      </div>


    </div>
  )
}

export default Videos