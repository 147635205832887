import React, { useState } from 'react'
import Compet from './competences/Compet'
import Contact from './contact/Contact'
import NavA from './Nav1'
import Videos from './projets/Video'

const Centre = () => {
  const [choix, setChoix] = useState('projets');

  const choisir = (arg) => {
    setChoix(arg);
  }

  //---------------------------
  return (
    <div className='centre'>

      <NavA nav={choisir} />
      <div className='rubrique'>
        {choix === 'projets' && <Videos />}
        {choix === 'compet' && <Compet />}
        {choix === 'contact' && <Contact changer={() => setChoix('projets')} />}
      </div>

    </div>
  )
}

export default Centre