
import { useEffect } from 'react';
import './App.css';
import Centre from './comp/centre/Centre';
import Footer from './comp/Footer';
import Header from './comp/Header';

function App() {

  useEffect(()=>{
    if(! window.document.documentElement.fullscreenElement){
      window.document.documentElement.requestFullscreen();
    }
    
  },[]);

  return (
    <div className="container"><Header/><Centre/><Footer/></div>
  );
}

export default App;
