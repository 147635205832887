import React from 'react'
import mel from './../images/mel.png'

const Header = () => {
  return (
    <div className='cadre-header'>
      <div className='header'>
        <div className='portrait'>
          <img alt='me' src={mel} />
        </div>
        <div className='titre'>
          <div className='petitsize'>Mélanie VANDERVAEREN</div>
          <div>DEVELOPPEUR-CONCEPTEUR  <br />
            d'applications Web JAVASCRIPT</div>
        </div>
      </div>
    </div>
  )
}

export default Header