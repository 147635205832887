import React, { useState } from 'react'

const NavB = ({ trier }) => {

  const [choix, setChoix] = useState('tous');

  const choisir = arg => {
    setChoix(arg);
    trier(arg);
  }

  return (
    <div id='nav2'>
      <div className={choix === 'tous' ? 'selected' : ''} onClick={() => choisir('tous')}>Tous</div>
      <div className={choix === 'jeu' ? 'selected' : ''} onClick={() => choisir('jeu')}>Jeux</div>
      <div className={choix === 'app' ? 'selected' : ''} onClick={() => choisir('app')}>App Web</div>
      <div className={choix === 'landing' ? 'selected' : ''} onClick={() => choisir('landing')}>Landing pages</div>
    </div>
  )
}

export default NavB