import React from 'react'
import banderolle from './../../../images/bandeau.png'
import ln from './../../../images/ln.png'
import gh from './../../../images/gh.png'

const Compet = () => {
  return (
    <div className='compet'>

      <div className='banderolle'> <img src={banderolle} alt='banderolle' /></div>
      <div className='techno'>HTML5-CSS3-JAVASCRIPT-TYPESCRIPT-ANGULAR-REACTJS-REDUX-NODEJS-FIREBASE-EXPRESSJS-MONGODB-APIREST-GITHUB-PWA-FIGMA</div>
      <div className='formation'>
        <h1>MES FORMATIONS</h1>
        <table>
          <tbody>
          <tr>
            <th>IFOCOP</th>
            <td>Certification professionnelle <br/> de niveau 6 (bac +3) <br />
              Code NSF 326 <br />
              Diplôme "Concepteur développeur Fullstack Javascript "
              <br/> <a href="https://www.ifocop.fr/certifications/?key=2CBE7D49294B391C1CB67E790C8A33848623DBC1D09376B51720503392ED7D2AZ1lPcWpEMEovbVpXd0YxSitOdjA1Vmw4eXF5MTVaN1YxMHJjYzdoaFpFdXhqNFd0" target="_blank" rel='noopener noreferrer'> lien blockchain</a></td>
          </tr>
          <tr>
            <th>UDEMY</th>
            <td> <br/><br/>
              <ul>
                <li>PWA</li>
                <li>Firebase</li>
                <li>Angular</li>
                <li>ReactJS - Redux</li>
                <li>React native (en cours)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>LINKEDIN </th>
            <td> <br/><br/>
            En cours : DNS, VPN, Docker, DevOps, architectures serverless, services web, microservices, design patterns, méthodes Agile,...
            </td>
          </tr>
          </tbody>
        </table>

      </div>
      

      <a href="https://www.linkedin.com/in/melanie-vandervaeren/" target="_blank" rel="noopener noreferrer"> <div className='lien-a'><img alt='ln' src={ln} /> melanie-vandervaeren</div></a>
      <a href="https://github.com/mel-vdv" target="_blank" rel="noopener noreferrer"> <div className='lien-a'><img alt='ln' src={gh} /> mel-vdv</div></a>

    </div>
  )
}

export default Compet