import React, { useContext, useState } from 'react'
import { FbContext } from '../../../firebase';
import ln from './../../../images/ln.png'

const Contact = ({changer}) => {

   const [nom,setNom] = useState('');
   const [mail,setMail] = useState('');
   const [sujet,setSujet] = useState('');
   const [msg,setMsg] = useState('');
   //---------------------
   const firebase= useContext(FbContext);
   //---------------------
   const sendMail = (e)=>{
    //////////////////////////////////// imortant pour que la page ne s'actualise pas auto après le fomrulaire
    e.preventDefault();
    console.log('on envoie');
   firebase.addMessage().add({
    to:['melvdv@yahoo.fr'],
    message:{
      subject:"Nouveau message sur MELVDEV",
      text:"",
      html:`
      <p>Nom: ${nom}, Email: ${mail} </p>
      <p>Sujet : ${sujet} </p>
      <p>Message : ${msg}</p>
      `
     }
     }).then(()=>{console.log('message posté');changer(); })
     .catch('shit');
   }

  return (
    <div className='contact'>

    <form onSubmit={sendMail}>
        <input type='text' value={nom} onChange={val=>setNom(val.target.value)} placeholder='Nom'/>
        <input type='text' value={mail} onChange={val=>setMail(val.target.value)} placeholder='Email'/>
        <input type='text' value={sujet} onChange={val=>setSujet(val.target.value)} placeholder='Sujet'/>
        <textarea value={msg} onChange={val=>setMsg(val.target.value)} placeholder='Votre message...'></textarea>
        <input type='submit' value='envoyer'/>
    </form>
    
    <a href="https://www.linkedin.com/in/melanie-vandervaeren/" target="_blank" rel="noopener noreferrer"> <div className='lien-a'><img alt='ln' src={ln} /> melanie-vandervaeren</div></a>

    </div>
  )
}

export default Contact